<!-- =========================================================================================
    File Name: Main.vue
    Description: Main layout
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="layout--main">

    <div id="content-area">

      <div class="content-wrapper">

        <div class="router-view">
          <div class="router-content">

            <!-- <transition :name="routerTransition"></transition> -->

            <div class="content-area__content">
              <!-- <router-view @changeRouteTitle="changeRouteTitle"></router-view> -->
              <router-view></router-view>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import themeConfig         from '@/../themeConfig.js'

export default {
  components: {
  },
  data() {
    return {
    }
  },
  watch: {
  },
  computed: {
  },
  methods: {
  },
  created() {
  }
}

</script>

